import { useRef } from "react";
import { create, StateCreator } from "zustand";
import mapboxgl from "mapbox-gl";
import { createExploreDataSlice, ExploreDataSlice } from "./slices/exploreDataSlice";
import { createGeoJsonSlice, GeoJsonSlice } from "./slices/geoJsonSlice";
import { createMetricConfigSlice, MetricConfigSlice } from "./slices/metricConfigSlice";
import { createMetricScoreSlice, MetricScoreSlice } from "./slices/metricScoreSlice";
import { createScoringConfigSlice, ScoringConfigSlice } from "./slices/scoringConfigSlice";
import { createSelectors } from "../createSelectors";
import { Metric } from "../../types/geoJson";
import { BaseScoringConfig, FlatScoringConfig } from "../../types/scoringConfig";

interface MapSlice {
  mapRef: mapboxgl.Map | null;
  setMapRef: (mapRef: mapboxgl.Map) => void
}

const createMapSlice: StateCreator<
MapSlice,
[],
[],
MapSlice> = (set, get) => ({
    mapRef: null,
    setMapRef: (mapRef) => set({ mapRef }),
})

type MapBaseStore = MapSlice & ExploreDataSlice & GeoJsonSlice & MetricConfigSlice & MetricScoreSlice & ScoringConfigSlice;

export const useBoundStore = create<MapBaseStore>()((...a) => ({
  ...createMapSlice(...a),
  ...createExploreDataSlice(...a),
  ...createGeoJsonSlice(...a),
  ...createMetricScoreSlice(...a),
  ...createMetricConfigSlice(...a),
  ...createScoringConfigSlice(...a)
}));

export const useStore = createSelectors(useBoundStore);

export function getScore(
  scores: number[] | undefined | null,
  metric: Metric
): number | null {
  if (metric.toLowerCase() === "overall") {
    metric = "overall";
  }
  const geographyLevel = useStore.getState().geographyLevel;
  const mapping = useStore.getState().metricMappingCollection?.[geographyLevel];
  if (!mapping) return null;
  const index = mapping.indexOf(metric);
  if (!scores || index === -1) {
    return null;
  }
  const rawScore = scores[index];
  return rawScore === null || rawScore === undefined ? null : rawScore;
}

export function useFlatConfig() {
  const config = useStore((state) => state.scoringConfig);
  const memoized = useRef<{
    lastConfig: BaseScoringConfig | null;
    lastFlatConfig: { [key: string]: FlatScoringConfig };
  }>({
    lastConfig: null,
    lastFlatConfig: {},
  });

  const getBase = ({
    level,
    name,
    weight,
    description,
    long_description,
  }: BaseScoringConfig): FlatScoringConfig => ({
    level,
    name,
    weight,
    description,
    long_description,
  });

  if (config !== memoized.current.lastConfig) {
    memoized.current.lastConfig = config;
    if (!config) {
      memoized.current.lastFlatConfig = {};
    } else {
      const flatConfig: Array<FlatScoringConfig> = config.items.flatMap(
        (category) => [
          getBase(category),
          ...category.items.flatMap((subcategory) => [
            getBase(subcategory),
            ...subcategory.items.flatMap((indicator) => [
              getBase(indicator),
              ...indicator.items.map((subindicator) => getBase(subindicator)),
            ]),
          ]),
        ]
      );
      memoized.current.lastFlatConfig = Object.fromEntries(
        flatConfig.map((subcategory) => [subcategory.name, subcategory])
      );
    }
  }

  return memoized.current.lastFlatConfig;
}
