import { Geometry, CenterZoom, GeojsonFeature } from "./types/geoJson";
import { LngLatLike } from "mapbox-gl";

export function getQuintileLabel(score: number | null): string {
  if (score === null) return "No data";
  if (score <= 0.2) return "Critical";
  if (score <= 0.4) return "At Risk";
  if (score <= 0.6) return "Average";
  if (score <= 0.8) return "Strong";
  return "Excellent";
}

export function isValidCenter(center: LngLatLike | null): boolean {
  if (center === null) return false;

  if (Array.isArray(center)) {
    return (
      center.length === 2 &&
      center.every((coord) => coord !== 0 && !isNaN(coord))
    );
  } else if ("lng" in center && "lat" in center) {
    return (
      center.lng !== 0 &&
      !isNaN(center.lng) &&
      center.lat !== 0 &&
      !isNaN(center.lat)
    );
  } else if ("lon" in center && "lat" in center) {
    return (
      center.lon !== 0 &&
      !isNaN(center.lon) &&
      center.lat !== 0 &&
      !isNaN(center.lat)
    );
  }

  return false;
}

export function getCategoryDescription(category: string): string {
  return (
    {
      "Baseline Vulnerability":
        "Physical exposure to the impacts of climate change",
      "Climate Impacts": "Capacity to prepare for and recover from adversity",
      Capacity: "A community's capacity to accommodate growth",
    }[category] || ""
  );
}

export function getGeometryCenterZoom(geometry: Geometry): CenterZoom | null {
  let coordinates: number[][];

  if (geometry.type === "Polygon") {
    coordinates = geometry.coordinates[0];
  } else if (geometry.type === "MultiPolygon") {
    coordinates = geometry.coordinates[0][0];
  } else {
    return null;
  }

  const minLon = Math.min(...coordinates.map((coord) => coord[0]));
  const maxLon = Math.max(...coordinates.map((coord) => coord[0]));
  const minLat = Math.min(...coordinates.map((coord) => coord[1]));
  const maxLat = Math.max(...coordinates.map((coord) => coord[1]));

  const centerLon = (minLon + maxLon) / 2;
  const centerLat = (minLat + maxLat) / 2;

  const zoomLevel =
    0 - Math.log2(Math.max(maxLon - minLon, maxLat - minLat) / 360);

  return { center: { lat: centerLat, lng: centerLon }, zoom: zoomLevel };
}

interface Color {
  r: number;
  g: number;
  b: number;
  pos: number;
}

export function calculatePercentiles(data: number[]) {
  const sorted = [...data].sort((a, b) => a - b);
  return data.map((value) => {
    const rank = sorted.indexOf(value) + 1;
    return rank / data.length;
  });
}

export function scoreColor(num: number | null): string {
  if (num === null) {
    return "#808080";
  }
  const colors: Color[] = [
    { r: 164, g: 40, b: 4, pos: 0 },
    { r: 238, g: 165, b: 85, pos: 0.25 },
    { r: 233, g: 232, b: 164, pos: 0.5 },
    { r: 88, g: 187, b: 200, pos: 0.75 },
    { r: 37, g: 119, b: 149, pos: 1 },
  ];

  num = Math.max(0, Math.min(1, num));

  let lowerColor = colors[0];
  let upperColor = colors[colors.length - 1];

  for (let i = 0; i < colors.length - 1; i++) {
    if (num >= colors[i].pos && num <= colors[i + 1].pos) {
      lowerColor = colors[i];
      upperColor = colors[i + 1];
      break;
    }
  }

  const factor = (num - lowerColor.pos) / (upperColor.pos - lowerColor.pos);
  const r = Math.round(lowerColor.r + factor * (upperColor.r - lowerColor.r));
  const g = Math.round(lowerColor.g + factor * (upperColor.g - lowerColor.g));
  const b = Math.round(lowerColor.b + factor * (upperColor.b - lowerColor.b));

  const hex = (c: number) => c.toString(16).padStart(2, "0");
  return `#${hex(r)}${hex(g)}${hex(b)}`.toUpperCase();
}

export const roundToNearest = (value: number, nearest: number): number => {
  return Math.round(value / nearest) * nearest;
};

export function hexToRgba(hex: string, opacity = 1): string {
  hex = hex.replace(/^#/, "");
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export function getCentroid(feature: GeojsonFeature): [number, number] {
  let center: [number, number] = [0, 0];
  const { coordinates, type } = feature.geometry;

  if (type === "Polygon") {
    const coords = coordinates as [number, number][][];
    center = coords[0].reduce(
      (centroid: [number, number], coord: [number, number]) => {
        centroid[0] += coord[0] / coords[0].length;
        centroid[1] += coord[1] / coords[0].length;
        return centroid;
      },
      [0, 0]
    );
  } else if (type === "MultiPolygon") {
    const coords = coordinates as [number, number][][][];
    center = coords[0][0].reduce(
      (centroid: [number, number], coord: [number, number]) => {
        centroid[0] += coord[0] / coords[0][0].length;
        centroid[1] += coord[1] / coords[0][0].length;
        return centroid;
      },
      [0, 0]
    );
  }

  return center;
}

export function getOrdinal(n: number): string {
  const s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}
