import { StateCreator } from "zustand";
import { ExploreData, GeographyLevel, Metric } from "../../../types/geoJson";
import { DomainTab, SidePanelTab } from "../../../types/sidePanel";

export interface ExploreDataSlice {
    geographyLevel: GeographyLevel;
    activeTab: SidePanelTab;
    domainTab: DomainTab;
    mapMetric: Metric;
    sidePanelData: ExploreData | null;
    exploreData: ExploreData | null;
    setActiveTab: (activeTab: SidePanelTab) => void;
    setDomainTab: (domainTab: DomainTab) => void;
    setGeographyLevel: (geographyLevel: GeographyLevel) => void;
    setMapMetric: (mapMetric: Metric) => void;
    setSidepanelData: (data: ExploreData | null) => void;
    setExploreData: (data: ExploreData | null) => void;
}

export const createExploreDataSlice: StateCreator<
ExploreDataSlice,
[], 
[], 
ExploreDataSlice
> = (set, get) => ({
    geographyLevel: GeographyLevel.csa,
    activeTab: SidePanelTab.Explore,
    domainTab: DomainTab.Overall,
    mapMetric: DomainTab.Overall,
    sidePanelData: null,
    exploreData: null,
    setActiveTab: (activeTab) => set({ activeTab }),
    setDomainTab: (domainTab) => set({ domainTab }),
    setGeographyLevel: (geographyLevel) => set({ geographyLevel }),
    setMapMetric: (mapMetric) => set({ mapMetric }),
    setSidepanelData: (sidePanelData) => set({ sidePanelData }),
    setExploreData: (exploreData) => set({ exploreData })
})