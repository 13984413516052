import { create, StateCreator } from "zustand";
import { createSelectors } from "../createSelectors";
import { AuthStatus } from "../../types/user";

interface UserSlice {
    authStatus: AuthStatus | null;
    setAuthStatus: (data: AuthStatus) => void;
}

// Keeping this as a slice even though it's in it's own store in case we want to move it.
const createUserSlice: StateCreator<
    UserSlice,
    [], 
    [], 
    UserSlice
> = (set) => ({
    authStatus: null,
    setAuthStatus: (data: AuthStatus) => set({ authStatus: data }),
})

const useUserStoreBase = create<UserSlice>()((...a) => ({
    ...createUserSlice(...a),
}));

export const useUserStore = createSelectors(useUserStoreBase);