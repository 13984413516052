import React from "react";
import styled from "styled-components";
import { getScore, useStore } from "../stores/map/store";
import { getQuintileLabel, scoreColor } from "../utils";
import { BodyTextBold, BodyTextLight } from "../text";
import { SmMediumTitle } from "../text";
import { useCompareStore, useCompareUrlSync } from "../stores/compare/store";
import { BaseScoringConfig } from "../types/scoringConfig";

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  padding: 12px;
  text-align: left;
  border-bottom: 2px solid #ddd;
`;

const Td = styled.td`
  padding: 12px;
  border-bottom: 1px solid #ddd;
`;

const ScoreCell = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  padding: 8px;
  border-radius: 4px;
  color: white;
  text-align: center;
`;

const CategoryName = styled(BodyTextBold)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const Breadcrumb = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
`;

const BreadcrumbItem = styled(SmMediumTitle)`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Separator = styled.span`
  color: #666;
  font-size: 14px;
  user-select: none;
`;

const ComparisonTable = ({
  subcategories,
}: {
  subcategories: BaseScoringConfig[];
}) => {
  const { locations, categoryPath, setCategoryPath } = useCompareStore(
    (state) => ({
      locations: state.locations,
      categoryPath: state.categoryPath,
      setCategoryPath: state.setCategoryPath,
    })
  );
  const getLocationProperties  = useStore((state) => state.getLocationProperties)

  // Add URL sync
  useCompareUrlSync();

  const categoryParts = categoryPath.split(".");

  const handleCategoryClick = (subcategory: BaseScoringConfig) => {
    const newPath = categoryPath
      ? `${categoryPath}.${subcategory.name}`
      : subcategory.name;
    if ("items" in subcategory) {
      setCategoryPath(newPath);
    }
  };

  return (
    <>
      {categoryPath && (
        <Breadcrumb>
          {categoryParts.map((part, i) => (
            <React.Fragment key={part}>
              <BreadcrumbItem
                onClick={() => {
                  const newPath = categoryParts.slice(0, i + 1).join(".");
                  setCategoryPath(newPath);
                }}
              >
                {part}
              </BreadcrumbItem>
              {i < categoryParts.length - 1 && <Separator>›</Separator>}
            </React.Fragment>
          ))}
        </Breadcrumb>
      )}
      <Table>
        <thead>
          <tr>
            <Th />
            {locations.map((loc) => (
              <Th key={loc.id}>
                {getLocationProperties(loc.level, loc.id)?.properties?.name}
              </Th>
            ))}
          </tr>
        </thead>
        <tbody>
          {subcategories.map((subconfig) => (
            <tr key={subconfig.name}>
              <Td>
                <CategoryName onClick={() => handleCategoryClick(subconfig)}>
                  {subconfig.name}
                </CategoryName>
                <br />
                <BodyTextLight>{subconfig.description}</BodyTextLight>
              </Td>
              {locations.map((loc) => {
                const score = getScore(
                  getLocationProperties(loc.level, loc.id)?.properties?.scoreTiers,
                  subconfig.name
                );
                const percentile =
                  score === null ? null : Math.round(score * 100);
                return (
                  <Td key={loc.id}>
                    <ScoreCell color={scoreColor(score)}>
                      <BodyTextBold>{getQuintileLabel(score)}</BodyTextBold>
                      {percentile !== null && (
                        <div>
                          <BodyTextLight>
                            {percentile}th percentile
                          </BodyTextLight>
                        </div>
                      )}
                    </ScoreCell>
                  </Td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default ComparisonTable;
