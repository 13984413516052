import React, { useCallback, useState } from "react";
import { useApiRequest } from "../network";
import { useStore } from "../stores/map/store";
import styled from "styled-components";
import { SegmentedControl } from "./SegmentedControl";
import { LogoTitle, PageTitle } from "../text";
import { useUserStore } from "../stores/user/store";
import { GeographyLevel, geographyLevelToLabelMap } from "../types/geoJson";
const NavBarContainer = styled.div`
  width: 100%;
  background-color: white;
  padding: 2px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid grey;
  max-height: 60px;
  min-height: 60px;
`;

const LogoImage = styled.img`
  height: 3rem; /* equivalent to h-12 */
  margin: 0 1rem; /* equivalent to mx-4 */
`;

const UserSection = styled.div`
  margin-right: 1rem; /* equivalent to mr-4 */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  font-size: 0.875rem; /* equivalent to text-sm */
  font-weight: medium;
  min-width: 200px;
`;

const Button = styled.button`
  background-color: #3182ce; /* equivalent to bg-blue-500 */
  &:hover {
    background-color: #2b6cb0; /* equivalent to hover:bg-blue-700 */
  }
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem; /* equivalent to py-2 px-4 */
  border-radius: 0.375rem; /* equivalent to rounded */
  transition: background-color 0.3s; /* equivalent to transition duration-300 */
`;

const LoginButton = styled(Button)`
  background-color: transparent;
  border: 2px solid #3182ce;
  color: #3182ce;
  font-weight: 600;
  padding: 0.5rem 1.25rem;
  transition: all 0.2s ease;
  margin-right: 1rem;

  &:hover {
    background-color: #3182ce;
    color: white;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

export default function NavBar() {
  const makeApiRequest = useApiRequest();
  const [isHovered, setIsHovered] = useState(false);
  const authStatus = useUserStore.use.authStatus();
  const setAuthStatus = useUserStore.use.setAuthStatus();
  const { geographyLevel, setGeographyLevel, exploreData } = useStore(
    (state) => ({
      geographyLevel: state.geographyLevel,
      setGeographyLevel: state.setGeographyLevel,
      exploreData: state.exploreData,
    })
  );
  const user = authStatus?.user;

  const handleLogin = () => {
    window.location.href = "/api/login";
  };

  const handleLogout = () => {
    makeApiRequest({
      method: "POST",
      endpoint: "/logout",
      onSuccess: ({ redirectUrl }) => {
        setAuthStatus({ user: null });
        window.location.href = redirectUrl;
      },
      hideSuccessSnackbar: true,
    });
  };

  // thinking if we can move this functionality to the store as a function like getHasTractData?
  const hasTractData = useStore(
    (state) =>
      Object.values(state.geoJsonCollection?.[GeographyLevel.tract] ?? {})
        .length > 0
  );

  const getSegmentedControlOptions = useCallback(() => {
    const filteredOptions = Object.values(GeographyLevel).filter(
      (geographyLevel) =>
        geographyLevel !== GeographyLevel.tract || hasTractData
    );
    return filteredOptions.map((geographyLevel) => ({
      value: geographyLevel,
      label: geographyLevelToLabelMap[geographyLevel],
    }));
  }, [hasTractData]);

  return (
    <NavBarContainer>
      <div
        className="all the way left"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start", // Align items to the start
          flex: 1, // Allow this section to take up available space
          cursor: "pointer",
        }}
        onClick={() => (window.location.href = "/")}
      >
        <LogoImage src="/logo.png" alt="Climate Atlas" />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <LogoTitle>Climate</LogoTitle>
          <LogoTitle style={{ marginTop: -6 }}>Atlas</LogoTitle>
        </div>
      </div>
      <PageTitle
        className="in the middle"
        style={{ flex: 1, textAlign: "center" }}
      >
        Climate Destination Index
      </PageTitle>
      <div
        className="all the way right"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end", // Align items to the end
          flex: 1, // Allow this section to take up available space
        }}
      >
        {!exploreData && (
          <SegmentedControl
            value={geographyLevel}
            options={getSegmentedControlOptions()}
            onChange={(value) => setGeographyLevel(value as GeographyLevel)}
          />
        )}
        {user ? (
          <UserSection
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {isHovered ? (
              <LoginButton onClick={handleLogout}>Logout</LoginButton>
            ) : (
              <p>{user.email}</p>
            )}
          </UserSection>
        ) : (
          <div>
            <LoginButton onClick={handleLogin}>Sign In</LoginButton>
          </div>
        )}
      </div>
    </NavBarContainer>
  );
}
