import { Metric } from "./geoJson";

export enum ScoringConfigTier {
  overall = "overall",
  domain = "domain",
  category = "category",
  subcategory = "subcategory",
  indicator = "indicator",
  subindicator = "subindicator",
}

export interface BaseScoringConfig {
  level: ScoringConfigTier;
  name: Metric;
  weight: number;
  description: string;
  long_description: string | null;
  items: BaseScoringConfig[];
}

export type FlatScoringConfig = Omit<BaseScoringConfig, "items">

export interface SubindicatorScoringConfig
  extends Omit<BaseScoringConfig, "items"> {
  level: ScoringConfigTier.subindicator;
  datapoint_key: string;
  source?: string | null;
}
