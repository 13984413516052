import React from "react";
import { getScore, useStore } from "../stores/map/store";
import { scoreColor } from "../utils";
import { DefaultScoreCard } from "./ScoreCard";
import useBenchmarkSelector from "./BenchmarkSelector";
import styled from "styled-components";
import { Title, BodyText, SmallTitle } from "../text";

const Container = styled.div`
  padding-top: 2rem;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ScoreCardContainer = styled.div`
  flex: 1;
  padding: 1rem;
  margin-right: 1rem;
`;

const CoreScoreContainer = styled.div`
  flex: 1;
  margin-top: 2rem;
`;

const BenchmarkText = styled(BodyText)`
  margin-bottom: 1rem;
`;

const RelativeContainer = styled.div`
  position: relative;
`;

const AbsoluteContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FlexEndContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  margin-left: 2rem;
`;

const CategoryLabel = styled.span`
  display: block;
  margin-top: 0.5rem;
  text-align: center;
  margin-left: 2rem;
  width: 33.3%;
`;

const ScoreComposition = () => {
  const { exploreData, scoringConfig, getGeographyData } = useStore(
    (state) => ({
      exploreData: state.exploreData,
      scoringConfig: state.scoringConfig,
      getGeographyData: state.getGeographyData,
    })
  );

  const [benchmark, BenchmarkSelector] = useBenchmarkSelector({
    benchmarks: ["Nation", "State", "Region"],
  });

  if (!exploreData || !scoringConfig) {
    return null;
  }

  const geographyData = getGeographyData(exploreData?.level);

  const categoryConfigs = scoringConfig?.items.filter(
    (subconfig) =>
      getScore(exploreData?.properties.scoreTiers, subconfig.name) !== null
  );

  if (!exploreData?.properties.scoreTiers || !geographyData) {
    return null;
  }

  return (
    <Container>
      <Title>Overall Score Composition</Title>
      <FlexContainer>
        <ScoreCardContainer>
          {categoryConfigs?.map((subconfig) => (
            <DefaultScoreCard
              key={subconfig.name}
              config={subconfig}
              properties={exploreData.properties}
            />
          ))}
        </ScoreCardContainer>
        <CoreScoreContainer>
          <SmallTitle>CORE Score Composition</SmallTitle>
          <BenchmarkText>Benchmark: {benchmark}</BenchmarkText>
          <RelativeContainer>
            <AbsoluteContainer>
              {[...Array(11)].map((_, i) => (
                <div key={i} className="flex items-center">
                  <span className="text-sm text-gray-600">{100 - i * 10}</span>
                  <div
                    className="flex-1"
                    style={{
                      borderTop: "1px solid black",
                      opacity: 0.2,
                    }}
                  />
                </div>
              ))}
            </AbsoluteContainer>
            <FlexEndContainer>
              {categoryConfigs?.map((subconfig) => (
                <div className="flex-1 relative" key={subconfig.name}>
                  <div
                    style={{
                      backgroundColor: scoreColor(
                        getScore(
                          exploreData.properties.scoreTiers,
                          subconfig.name
                        ) || 0
                      ),
                      height: `${
                        (getScore(
                          exploreData.properties.scoreTiers,
                          subconfig.name
                        ) || 0) * 300
                      }px`,
                    }}
                  />
                </div>
              ))}
            </FlexEndContainer>
          </RelativeContainer>
          <div className="flex justify-around">
            {categoryConfigs?.map((subconfig) => (
              <CategoryLabel key={subconfig.name}>
                {subconfig.name}
              </CategoryLabel>
            ))}
          </div>
          {BenchmarkSelector}
        </CoreScoreContainer>
      </FlexContainer>
    </Container>
  );
};

export default ScoreComposition;
