import { useEffect, useState } from "react";
import { useStore } from "../stores/map/store";
import { useApiRequest } from "../network";
import { GeographyLevel, Metric } from "../types/geoJson";

export const useGetOrFetchScores = (
  level: GeographyLevel,
  metric: Metric
): { scores: { [key: string]: number } | null | undefined; loading: boolean } => {
  if (metric === "Overall") {
    metric = "overall";
  }
  const { metricScores, setMetricScores } = useStore((state) => ({
    metricScores: state.metricScores,
    setMetricScores: state.setMetricScores,
  }));
  const [loading, setLoading] = useState(false);
  const makeApiRequest = useApiRequest();
  const currentScores = metricScores[level]?.[metric];

  useEffect(() => {
    const fetchScores = async () => {
      if (!currentScores && metric) {
        setLoading(true);

        await makeApiRequest({
          method: "GET",
          endpoint: `/scores?level=${level.replace('Code', '')}&metric=${encodeURIComponent(
            metric
          )}`,
          hideErrorSnackbar: true,
          hideSuccessSnackbar: true,
          onSuccess: (data) => {
            setMetricScores(level, metric, data.scores);
            setLoading(false);
          },
          onError: (error) => {
            console.error("Error fetching scores:", error);
          },
        });
      }
    };

    fetchScores();
  }, [level, metric]);

  return {
    scores: currentScores,
    loading,
  };
};
