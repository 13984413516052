import React, { useState } from "react";
import { getScore, useStore } from "../stores/map/store";
import styled from "styled-components";
import FoldUnfoldButton from "./FoldUnfoldButton";
import { scoreColor } from "../utils";
import { DomainTab } from "../types/sidePanel";

const CategoryItem = styled.div`
  text-align: left;
  margin-top: 4px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
`;

const CategoryName = styled.p<{ $isActive: boolean }>`
  font-weight: 500;
  font-family: "Barlow", sans-serif;
  cursor: pointer;
  color: ${(props) => (props.$isActive ? "#178ab0" : "black")};
`;

const SubCategory = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

const ScoreBox = styled.div`
  border-radius: 50%;
  min-width: 12px;
  min-height: 12px;
  height: 12px;
  width: 12px;
  margin-right: 8px;
  background-color: ${(props) => props.color};
`;

export default function Explore() {
  const { scoringConfig, domainTab } = useStore((state) => ({
    scoringConfig: state.scoringConfig,
    domainTab: state.domainTab,
  }));
  const [unfoldedCategories, setUnfoldedCategories] = useState<Set<string>>(
    new Set()
  );

  const toggleUnfold = (name: string) => {
    setUnfoldedCategories((prevUnfoldedCategories) => {
      const newUnfolded = new Set(prevUnfoldedCategories);
      if (newUnfolded.has(name)) {
        newUnfolded.delete(name);
      } else {
        newUnfolded.add(name);
      }
      return newUnfolded;
    });
  };

  const isUnfolded = (name: string) => unfoldedCategories.has(name);

  const categoryConfig =
    domainTab === DomainTab.Overall
      ? scoringConfig
      : scoringConfig?.items.find((item) => item.name === domainTab);
  if (!categoryConfig) {
    return null;
  }

  return (
    <div>
      {categoryConfig.items.map(({ name, items, weight }) => (
        <CategoryItem key={name}>
          <CategorySection
            name={name}
            weight={weight}
            allowUnfold={true}
            isUnfolded={isUnfolded(name)}
            toggleUnfold={toggleUnfold}
          />
          {isUnfolded(name) && (
            <SubCategory>
              {items.map((sub) => (
                <CategorySection
                  key={sub.name}
                  name={sub.name}
                  weight={sub.weight}
                  allowUnfold={false}
                  isUnfolded={isUnfolded(sub.name)}
                  toggleUnfold={toggleUnfold}
                />
              ))}
            </SubCategory>
          )}
        </CategoryItem>
      ))}
    </div>
  );
}

function CategorySection({
  name,
  weight,
  allowUnfold,
  isUnfolded,
  toggleUnfold,
}: {
  name: string;
  weight: number;
  allowUnfold: boolean;
  isUnfolded: boolean;
  toggleUnfold: (name: string) => void;
}) {
  const { sidePanelData, mapMetric, setMapMetric } = useStore((state) => ({
    sidePanelData: state.sidePanelData,
    mapMetric: state.mapMetric,
    setMapMetric: state.setMapMetric,
  }));

  const extraInfo = (name: string, weight: number) => {
    if (!sidePanelData) {
      return null;
    }
    const score = getScore(sidePanelData.properties.scoreTiers, name);
    if (score === null) {
      return null;
    }
    const scoreTier = (score * 100).toFixed(0);

    return (
      <p className="text-xs ml-4" style={{ fontFamily: "Barlow; sans-serif" }}>
        {scoreTier}%
      </p>
    );
  };

  const box = (name: string) => {
    if (!sidePanelData) {
      return null;
    }
    const scoreTier = getScore(sidePanelData.properties.scoreTiers, name);
    return <ScoreBox color={scoreColor(scoreTier)} />;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "8px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        {box(name)}
        <CategoryName
          $isActive={name === mapMetric}
          onClick={() => setMapMetric(name)}
        >
          {name}
        </CategoryName>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        {extraInfo(name, weight)}
        {allowUnfold && (
          <FoldUnfoldButton
            unfolded={isUnfolded}
            toggleUnfold={() => toggleUnfold(name)}
          />
        )}
      </div>
    </div>
  );
}
