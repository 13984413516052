import React from "react";
import GradientBar from "./GradientBar";
import ScorePill from "./ScorePill";
import { getScore, useStore } from "../stores/map/store";
import { getQuintileLabel, scoreColor } from "../utils";
import useBenchmarkSelector from "./BenchmarkSelector";
import { SmallTitleNotBold, BodyTextLight, BodyTextBold } from "../text";
import styled from "styled-components";
import { Properties } from "../types/geoJson";
import {
  BaseScoringConfig,
  ScoringConfigTier,
  SubindicatorScoringConfig,
} from "../types/scoringConfig";

const HoverableTitle = styled(SmallTitleNotBold)`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const SourceText = styled(BodyTextLight)`
  font-size: 0.8rem;
  font-style: italic;
`;

const ScoreCard = ({
  title,
  description,
  percentile,
  label,
  color,
  reference,
  onClick,
  source,
}: {
  title: string;
  description: string;
  percentile: number | null;
  label: string;
  color: string;
  reference: string;
  onClick?: null | (() => void);
  source: string | null;
}) => (
  <div className="mb-3">
    {onClick ? (
      <HoverableTitle onClick={onClick}>{title}</HoverableTitle>
    ) : (
      <SmallTitleNotBold>{title}</SmallTitleNotBold>
    )}
    <div>
      <BodyTextLight>{description}</BodyTextLight>
      {source && percentile !== null && (
        <>
          <br />
          <SourceText>Source: {source}</SourceText>
        </>
      )}
    </div>
    <div className="flex flex-col">
      <div className="flex flex-row items-center my-2">
        <ScorePill label={label} color={color} />
        {percentile !== null && (
          <div className="ml-8">
            <BodyTextBold>{percentile}th</BodyTextBold>{" "}
            <BodyTextLight>percentile in </BodyTextLight>
            <BodyTextBold>{reference}</BodyTextBold>
          </div>
        )}
      </div>
      <GradientBar percentile={percentile} />
    </div>
  </div>
);

export function DefaultScoreCard({
  config,
  properties,
  onClick,
}: {
  config: BaseScoringConfig | SubindicatorScoringConfig;
  properties: Properties;
  onClick?: () => void;
}) {
  const key = config.name;
  const score = getScore(properties.scoreTiers, key);
  const [benchmark] = useBenchmarkSelector({ benchmarks: ["Nation"] });
  const onClickFinal = config.level === "subindicator" ? null : onClick;
  const source =
    config.level === ScoringConfigTier.subindicator
      ? (config as SubindicatorScoringConfig).source
      : null;
  console.log('source for key', key, source);
  return (
    <ScoreCard
      key={key}
      title={key}
      description={config.description}
      source={source || null}
      percentile={score === null ? null : Math.round(score * 100)}
      label={getQuintileLabel(score)}
      color={scoreColor(score)}
      reference={benchmark}
      onClick={onClickFinal}
    />
  );
}

export default ScoreCard;
