import React from "react";
import { useStore } from "../stores/map/store";
import styled from "styled-components";
import { SmallTitleNotBold } from "../text";
import { ExploreData, geographyLevelToLabelMap } from "../types/geoJson";
import { DomainTab } from "../types/sidePanel";

export default function TitleBox() {
  const {
    activeTab,
    domainTab,
    setDomainTab,
    setMapMetric,
    mapMetric,
    sidePanelData,
  } = useStore((state) => ({
    activeTab: state.activeTab,
    domainTab: state.domainTab,
    setDomainTab: state.setDomainTab,
    setMapMetric: state.setMapMetric,
    mapMetric: state.mapMetric,
    sidePanelData: state.sidePanelData,
  }));

  const domainTabs: DomainTab[] = Object.values(DomainTab);
  if (activeTab === "Compare") {
    return null;
  }

  return (
    <Container>
      <Title>
        {activeTab === "Explore" && <ExploreTitle domainTab={domainTab} />}
        {activeTab === "Inspect" && <InspectTitle data={sidePanelData} />}
      </Title>
      <TabContainer>
        {domainTabs.map((tab: DomainTab) => (
          <TabButton
            key={tab}
            onClick={() => {
              setDomainTab(tab);
              setMapMetric(tab);
            }}
            $isActive={tab === mapMetric}
            $selectedCurrently={domainTab === tab}
          >
            {tab}
          </TabButton>
        ))}
      </TabContainer>
    </Container>
  );
}

const ExploreTitle: React.FC<{ domainTab: DomainTab }> = ({ domainTab }) => (
  <div>
    <SmallTitleNotBold>
      {domainTab === "Overall" ? "CORES" : domainTab}
    </SmallTitleNotBold>
    <p style={{ marginTop: 4, marginBottom: 4 }}>
      {domainTab === "Overall"
        ? "Index combining data related to climate risk exposure, baseline resilience, and growth potential."
        : "Index combining data related to climate risk exposure, baseline resilience, and growth potential."}
    </p>
  </div>
);

const InspectTitle: React.FC<{ data: ExploreData | null }> = ({ data }) => (
  <div>
    {data?.properties.name && (
      <p className="font-semibold">{data.properties.name}</p>
    )}
    <div style={{ marginTop: 4, marginBottom: 4 }}>
      {data?.level && (<p className="text-xs">{geographyLevelToLabelMap[data.level].toUpperCase()}</p>)}
      <p className="text-xs">Benchmark: Nation</p>
    </div>
  </div>
);

const Container = styled.div``;

const Title = styled.h2`
  text-align: left;
  margin: 12px;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid;
`;

interface TabButtonProps {
  $isActive: boolean;
  $selectedCurrently: boolean;
}

const TabButton = styled.button<TabButtonProps>`
  padding: 2px 8px;
  font-size: x-small;
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  border-bottom: 2px solid;
  border-color: ${(props) =>
    props.$selectedCurrently ? "black" : "transparent"};
  color: ${(props) =>
    props.$isActive ? "#178ab0" : props.$selectedCurrently ? "black" : "gray"};
`;
