import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import Map from "../components/Map";
import { useApiRequest } from "../network";
import ComparisonPage from "../components/ComparisonPage";
import SidePanel from "../components/SidePanel";
import geojsonUrl from "../data/csas.geojson";
import geojsonCbsaUrl from "../data/cbsas.geojson";
import { useStore } from "../stores/map/store";
import Dive from "./Dive";
import { getCentroid } from "../utils";
import { GeographyLevel, GeojsonData } from "../types/geoJson";
import { SidePanelTab } from "../types/sidePanel";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

interface PanelProps {
  $isOpen: boolean;
}

const SidePanelContainer = styled.div<PanelProps>`
  width: 330px;
  transition: transform 0.3s ease-in-out;
`;

const BottomPanel = styled.div<PanelProps>`
  position: fixed;
  bottom: 0;
  right: 0;
  height: ${(props) => (props.$isOpen ? "calc(100vh - 60px)" : "80vh")};
  width: calc(100% - 316px);
  background-color: white;
  transform: translateY(${(props) => (props.$isOpen ? "0" : "100%")});
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const MapContainer = styled.div<PanelProps>`
  flex-grow: 1;
  transition: width 0.3s ease-in-out;
  width: calc(100% - 300px);
`;

export default function HomePage({
  initialTab,
}: {
  initialTab?: SidePanelTab;
}) {
  const { level: keyOfGeographyLevel, id } = useParams<{
    level: keyof typeof GeographyLevel;
    id: string;
  }>();
  const level = GeographyLevel[keyOfGeographyLevel ?? "csa"];
  const {
    exploreData,
    mapRef,
    activeTab,
    setActiveTab,
    setExploreData,
    setMetricConfig,
    setSidepanelData,
    setScoringConfig,
    mapGeoJsonDataToCollection,
    getGeographyData,
    getLocationProperties,
    getHasDataForCompare,
  } = useStore((state) => ({
    exploreData: state.exploreData,
    mapRef: state.mapRef,
    activeTab: state.activeTab,
    setActiveTab: state.setActiveTab,
    setExploreData: state.setExploreData,
    setMetricConfig: state.setMetricConfig,
    setSidepanelData: state.setSidepanelData,
    setScoringConfig: state.setScoringConfig,
    mapGeoJsonDataToCollection: state.mapGeoJsonDataToCollection,
    getGeographyData: state.getGeographyData,
    getLocationProperties: state.getLocationProperties,
    getHasDataForCompare: state.getHasDataForCompare,
  }));
  const [isBottomPanelOpen, setBottomPanelOpen] = useState(false);
  const geographyData = getGeographyData(level);

  const makeApiRequest = useApiRequest();

  useEffect(() => {
    if (initialTab && initialTab in SidePanelTab) {
      setActiveTab(initialTab);
    }
  }, [initialTab]);

  useEffect(() => {
    console.log("Loading config");
    makeApiRequest({
      method: "GET",
      endpoint: "/tiered-config",
      onSuccess: setScoringConfig,
      hideSuccessSnackbar: true,
    });
    makeApiRequest({
      method: "GET",
      endpoint: "/metric-config",
      onSuccess: setMetricConfig,
      hideSuccessSnackbar: true,
      // Already would error above anyway
      // and this can fail silently with the rest of the site working
      hideErrorSnackbar: true,
    });
    console.log("Metric config loaded");
  }, [setScoringConfig, setMetricConfig, makeApiRequest]);

  useEffect(() => {
    console.log("Loading CSA and CBSA data");
    const fetchAndStoreGeoJsonData = async (url: string, geographyLevel: GeographyLevel) => {
      fetch(url).then(async (response) => {
        const data: GeojsonData = await response.json();
        data.level = geographyLevel;
        mapGeoJsonDataToCollection(data);
      }).catch((error) => {
        console.error("Failed to load GeoJSON data level=", geographyLevel, "error=", error);
      })
    }
    
    Promise.all([
      fetchAndStoreGeoJsonData(geojsonUrl, GeographyLevel.csa), 
      fetchAndStoreGeoJsonData(geojsonCbsaUrl, GeographyLevel.cbsa)
    ]).then(() => {
      console.log("Loaded all CSA and CBSA Data");
    })
  }, [mapGeoJsonDataToCollection]);

  useEffect(() => {
    console.log("Loading explore data");
    if (initialTab !== "Inspect") {
      return;
    }
    if (id) {
      if (!geographyData) {
        return;
      }
      const properties = getLocationProperties(level, id)?.properties;
      if (properties) {
        const data = {
          level,
          properties,
        };
        setExploreData(data);
        setSidepanelData(data);
      }
    }
  }, [
    id,
    initialTab,
    level,
    getLocationProperties,
    geographyData,
    setExploreData,
    setActiveTab,
    setSidepanelData,
  ]);

  useEffect(() => {
    const openCode = exploreData?.properties[level];
    const shouldBeOpen =
      (activeTab === "Inspect" && !!openCode) || activeTab === "Compare";

    // Only update if the state actually needs to change
    setBottomPanelOpen((currentlyOpen) => {
      if (currentlyOpen !== shouldBeOpen) {
        return shouldBeOpen;
      }
      return currentlyOpen;
    });
    console.log("Setting bottom panel open", isBottomPanelOpen);

    // Only try to fly to location if we have a code
    if (openCode && mapRef && exploreData?.level) {
      const ourFeature = getLocationProperties(exploreData.level, openCode);
      if (ourFeature) {
        const center = getCentroid(ourFeature);
        if (center.every((coord) => coord !== 0 && !isNaN(coord))) {
          mapRef.flyTo({
            center,
            zoom: 6,
            speed: 0.8,
            curve: 1,
          });
        }
      }
    }
  }, [mapRef, exploreData, activeTab]);

  return (
    <PageContainer>
      <ContentContainer>
        <SidePanelContainer $isOpen={true}>
          <SidePanel />
        </SidePanelContainer>
        <MapContainer $isOpen={!isBottomPanelOpen}>
          <Map />
        </MapContainer>
      </ContentContainer>
      <BottomPanel $isOpen={isBottomPanelOpen}>
        {getHasDataForCompare() &&
          (activeTab === "Compare" ? <ComparisonPage /> : <Dive />)}
      </BottomPanel>
    </PageContainer>
  );
}
