import React from "react";
import RadarChart from "../components/RadarChart";
import Drilldown from "../components/Drilldown";
import CoreDrivers from "../components/CoreDrivers";
import ScoreComposition from "../components/ScoreComposition";
import PerformanceScatterChart from "../components/PerformanceScatterChart";
import styled from "styled-components";
import { useStore } from "../stores/map/store";
import CoreScore from "../components/CoreScore";
import { darken } from "polished";
import { useNavigate } from "react-router-dom";
import { GeographyLevel } from "../types/geoJson";

const SubNavBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f8f9fa;
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const Section = styled.div`
  height: 80vh;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dotted lightgray;
  background-color: #edeff0;
  &:nth-child(odd) {
    background-color: white;
  }
`;

const Container = styled.div`
  overflow-y: scroll;
  height: 100vh;
`;

const StyledButton = styled.button<{ color: string; disabled: boolean }>`
  background-color: ${(props) => (props.disabled ? "#ccc" : props.color)};
  color: white;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Barlow", sans-serif;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100px;

  &:hover {
    background-color: ${(props) =>
      props.disabled ? "#ccc" : darken(0.1, props.color)};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
  }
`;

export default function Dive() {
  const { setExploreData, exploreData, scoringConfig, getGeographyData } =
    useStore((state) => ({
      setExploreData: state.setExploreData,
      exploreData: state.exploreData,
      scoringConfig: state.scoringConfig,
      getGeographyData: state.getGeographyData,
    }));
  const navigate = useNavigate();

  if (!exploreData || !scoringConfig) return null;

  const geographyData = getGeographyData(exploreData.level);

  if (!geographyData) return null;

  return (
    <Container>
      <SubNavBar>
        <div>{exploreData?.properties.name}</div>
        <StyledButton
          color="#808080"
          disabled={false}
          onClick={() => {
            setExploreData(null);
            navigate("/");
          }}
        >
          Back
        </StyledButton>
      </SubNavBar>
      <div>
        <Section>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              justifyContent: "space-between",
              width: "100%",
              height: "100%",
            }}
          >
            <CoreScore />
            <RadarChart />
          </div>
        </Section>
        <Section>
          <ScoreComposition />
        </Section>
        {exploreData?.level !== GeographyLevel.tract && (
          <Section>
            <PerformanceScatterChart />
          </Section>
        )}
        {scoringConfig?.items.map((item, i) => (
          <Section key={i} style={{ minHeight: "80vh", height: "auto" }}>
            <Drilldown initialCategoryPath={`${item.name}`} />
          </Section>
        ))}
        <Section>
          <CoreDrivers />
        </Section>
      </div>
    </Container>
  );
}
