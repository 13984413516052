import { StateCreator } from "zustand";
import { GeographyLevel, GeojsonData, GeojsonFeature as GeoJsonFeature } from "../../../types/geoJson";
import { cloneDeep } from "lodash";

type GeoJsonCollection = Partial<Record<GeographyLevel, Record<string, GeoJsonFeature>>> | null;
type MetricMappingCollection = Partial<Record<GeographyLevel, string[]>> | null;

export interface GeoJsonSlice {
    geoJsonCollection: GeoJsonCollection;
    metricMappingCollection: MetricMappingCollection;
    mapGeoJsonDataToCollection: (...geoJsonDataSets: GeojsonData[]) => void;
    setGeoJsonCollection: (geoJsonCollection: GeoJsonCollection) => void;
    setMetricMappingCollection: (metricMappingCollection: MetricMappingCollection) => void;
    addTractData: (tractData: GeoJsonFeature[]) => void;
    getLocationProperties: (level: GeographyLevel, code: string) => GeoJsonFeature | undefined;
    getGeographyData: (level: GeographyLevel) => Record<string, GeoJsonFeature> | undefined;
    getHasDataForCompare: () => boolean;
    getGeoJsonData: (level: GeographyLevel) => GeojsonData | null;
}

export const createGeoJsonSlice: StateCreator<
GeoJsonSlice,
[], 
[], 
GeoJsonSlice
> = (set, get) => ({
    geoJsonCollection: null,
    metricMappingCollection: null,
    setGeoJsonCollection: (geoJsonCollection) => set({ geoJsonCollection }),
    mapGeoJsonDataToCollection: (...geoJsonDataSets) => set((state) => {
        const geoJsonCollection: GeoJsonCollection = cloneDeep(state.geoJsonCollection) ?? {};
        const metricMappingCollection: MetricMappingCollection = cloneDeep(state.metricMappingCollection) ?? {};
        geoJsonDataSets.forEach((geoJsonData) => {
            const { level, features, metrics } = geoJsonData;
            if (metrics) {
                // If all the metric mappings are the same across each GeographyLevel, we can flatten this to just an Array.
                metricMappingCollection[level] = metrics;
                if (level === GeographyLevel.cbsa) {
                    metricMappingCollection[GeographyLevel.tract] = metrics;
                }
            }

            geoJsonCollection[level] = {};
            
            features.forEach((feature) => {
                const featureId = feature.properties[level]!;
                geoJsonCollection[level]![featureId] = feature;
            })
            console.log('GeoJSON %s data loaded', level);
        })

        return { geoJsonCollection, metricMappingCollection }
    }),
    setMetricMappingCollection: (metricMappingCollection) => set({ metricMappingCollection }),
    addTractData: (tractData) => set((state) => {
        let collection = cloneDeep(state.geoJsonCollection);
        if (!collection) {
            collection = {};
        }
        if (!collection[GeographyLevel.tract]) {
            collection![GeographyLevel.tract] = {};
        }
        tractData.forEach((td) => {
            collection![GeographyLevel.tract]![td.properties[GeographyLevel.tract]!] = td;
        })
        return { geoJsonCollection: collection };
    }),
    getLocationProperties: (level, code) => get().geoJsonCollection?.[level]?.[code],
    getGeographyData: (level) => get().geoJsonCollection?.[level],
    getGeoJsonData: (level) => {
        const { geoJsonCollection, metricMappingCollection } = get();

        const metricMapping = metricMappingCollection?.[level];
        const features = geoJsonCollection?.[level]

        if (!features) {
            return null;
        }

        return ({
            type: "FeatureCollection",
            features: Object.values(features),
            level,
            metrics: metricMapping ? Object.values(metricMapping) : undefined,
        })
    },
    getHasDataForCompare: () => {
        const { geoJsonCollection } = get();
        return !!geoJsonCollection?.[GeographyLevel.csa] && 
            !!geoJsonCollection?.[GeographyLevel.cbsa]
    }
})