import { StateCreator } from "zustand";
import { BaseScoringConfig } from "../../../types/scoringConfig";


export interface ScoringConfigSlice {
    scoringConfig: BaseScoringConfig | null;
    setScoringConfig: (scoringConfig: BaseScoringConfig) => void;
}

export const createScoringConfigSlice: StateCreator<
ScoringConfigSlice,
[],
[],
ScoringConfigSlice> = (set, get) => ({
    scoringConfig: null,
    setScoringConfig: (scoringConfig) => set({ scoringConfig }),
})
