export enum SidePanelTab {
  Explore = "Explore",
  Inspect = "Inspect",
  Compare = "Compare",
}

export enum DomainTab {
  Overall = "Overall",
  GrowthPotential = "Growth Potential",
  BaselineResilience = "Baseline Resilience",
  HazardInsulation = "Hazard Insulation",
  PathToSustainability = "Path to Sustainability",
}
