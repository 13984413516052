import React from "react";
import ComparePageSelection from "./ComparePageSelection";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useStore, useFlatConfig, getScore } from "../stores/map/store";
import TitleBox from "./TitleBox";
import Explore from "./Explore";
import { scoreColor } from "../utils";
import Histogram from "./Histogram";
import { StyledButton, ButtonContainer } from "../text";
import { SidePanelTab } from "../types/sidePanel";
import { geographyLevelReverseIndex } from "../types/geoJson";

const ContentArea = styled.div`
  padding: 1rem;
`;

const HistogramContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const SidePanelContainer = styled.div`
  background-color: white;
  border-right: 1px solid grey;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
  height: calc(100vh - 60px);
`;

const TabNavigation = styled.div`
  border-bottom: 1px solid;
  display: flex;
  justify-content: space-between;
`;

const TabButton = styled.button<{ $isActive: boolean }>`
  padding: 0.25rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  font-family: "Barlow", sans-serif;
  border-bottom-width: 2px;
  border-color: ${(props) => (props.$isActive ? "black" : "transparent")};
  color: ${(props) => (props.$isActive ? "black" : "#717171")};
`;

export default function SidePanel() {
  const navigate = useNavigate();
  const {
    sidePanelData,
    activeTab,
    setActiveTab,
    setExploreData,
    mapMetric,
    exploreData,
  } = useStore((state) => ({
    sidePanelData: state.sidePanelData,
    activeTab: state.activeTab,
    setActiveTab: state.setActiveTab,
    setExploreData: state.setExploreData,
    mapMetric: state.mapMetric,
    exploreData: state.exploreData,
  }));

  const flatConfig = useFlatConfig();
  const mapMetricLevel = flatConfig[mapMetric]?.level;

  const handleExploreData = () => {
    if (sidePanelData) {
      const { level, properties } = sidePanelData;
      navigate(`/explore/${geographyLevelReverseIndex[level]}/${properties[level]}`);
      setExploreData(sidePanelData);
    }
  };

  const color = scoreColor(
    getScore(sidePanelData?.properties.scoreTiers, mapMetric) || 0.5
  );

  const isExploring = !!exploreData;

  return (
    <SidePanelContainer>
      <TabNavigation>
        {Object.values(SidePanelTab).map((tab) => (
          <TabButton
            key={tab}
            onClick={() => setActiveTab(tab)}
            $isActive={activeTab === tab}
          >
            {tab}
          </TabButton>
        ))}
      </TabNavigation>
      <div>
        <TitleBox />
      </div>
      <ContentArea>
        {activeTab === "Compare" ? <ComparePageSelection /> : <Explore />}
      </ContentArea>
      {["Explore", "Inspect"].includes(activeTab) && (
        <>
          <HistogramContainer>
            <Histogram
              numBuckets={mapMetricLevel === "subindicator" ? 2000 : 50}
            />
          </HistogramContainer>
          <ButtonContainer>
            <StyledButton
              color={sidePanelData ? color : "#ccc"}
              onClick={handleExploreData}
              disabled={isExploring || !sidePanelData}
            >
              Explore Data
            </StyledButton>
          </ButtonContainer>
        </>
      )}
      <br />
    </SidePanelContainer>
  );
}
