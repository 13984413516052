export type Metric = string;

export interface Polygon {
  type: "Polygon";
  coordinates: number[][][];
}

export interface MultiPolygon {
  type: "MultiPolygon";
  coordinates: number[][][][];
}

export type Geometry = Polygon | MultiPolygon;

export interface CenterZoom {
  center: {
    lat: number;
    lng: number;
  };
  zoom: number;
}

export interface Properties {
  csaCode: string;
  cbsaCode: string;
  tractCode: string | null;
  name: string;
  scoreTiers: number[];
}

export interface GeojsonFeature {
  type: "Feature";
  properties: Properties;
  geometry: Geometry;
}

export interface GeojsonData {
  type: "FeatureCollection";
  features: Array<GeojsonFeature>;
  metrics?: Array<string>;
  level: GeographyLevel;
}

export enum GeographyLevel {
  csa = "csaCode",
  cbsa = "cbsaCode",
  tract = "tractCode",
}

export const geographyLevelReverseIndex = {
  [GeographyLevel.csa]: "csa",
  [GeographyLevel.cbsa]: "cbsa",
  [GeographyLevel.tract]: "tract",
};

// This can be replaced and removed with a working nls setup.
export const geographyLevelToLabelMap = {
  [GeographyLevel.csa]: "CSA",
  [GeographyLevel.cbsa]: "CBSA",
  [GeographyLevel.tract]: "Tract",
};

export interface ExploreData {
  level: GeographyLevel;
  properties: Properties;
}

export type MetricScores = Partial<
  Record<GeographyLevel, Record<Metric, Record<string, number>>>
>;
