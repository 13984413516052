import React from "react";
import { getScore, useStore } from "../stores/map/store";
import { PolarArea } from "react-chartjs-2";
import {
  Chart,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { scoreColor, hexToRgba } from "../utils";
import styled from "styled-components";

Chart.register(RadialLinearScale, ArcElement, Tooltip, Legend);

interface PolarAreaData {
  labels: string[];
  datasets: {
    label: string;
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
    data: number[];
  }[];
}

const ChartContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const PolarAreaChartInner = ({ data }: { data: PolarAreaData }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      r: {
        ticks: { beginAtZero: true },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <ChartContainer>
      <PolarArea data={data} options={options as any} />
    </ChartContainer>
  );
};

const PolarAreaChart = () => {
  const { scoringConfig, exploreData, getGeographyData } = useStore(
    (state) => ({
      scoringConfig: state.scoringConfig,
      exploreData: state.exploreData,
      getGeographyData: state.getGeographyData,
    })
  );

  if (!exploreData || !scoringConfig) {
    return null;
  }

  const geographyData = getGeographyData(exploreData.level);

  if (!geographyData) return null;

  const labels = scoringConfig.items
    .map(({ items }) => items.map((i) => i.name))
    .flat();

  const data: PolarAreaData = {
    labels,
    datasets: scoringConfig.items.map(({ name, items }) => ({
      label: name,
      backgroundColor: hexToRgba(
        scoreColor(getScore(exploreData.properties.scoreTiers, name)),
        0.2
      ),
      borderColor: scoreColor(
        getScore(exploreData.properties.scoreTiers, name)
      ),
      borderWidth: 1,
      data: labels.map(
        (label) =>
          (items.map((i) => i.name).includes(label)
            ? getScore(exploreData.properties.scoreTiers, label) || 0
            : 0) * 100
      ),
    })),
  };

  return <PolarAreaChartInner data={data} />;
};

export default PolarAreaChart;
