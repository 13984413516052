import React from "react";
import { scoreColor } from "../utils";
import { useStore } from "../stores/map/store";
import { useGetOrFetchScores } from "../hooks/useGetOrFetchScores";

export default function Histogram({ numBuckets = 100 }) {
  const { sidePanelData, mapMetric, geographyLevel } = useStore((state) => ({
    sidePanelData: state.sidePanelData,
    mapMetric: state.mapMetric,
    geographyLevel: state.geographyLevel,
  }));
  const code = sidePanelData?.properties[sidePanelData.level];
  const { scores, loading } = useGetOrFetchScores(geographyLevel, mapMetric);
  if (loading || !mapMetric || !scores) {
    return null;
  }

  const scoreValues = Object.values(scores);
  const minScore = Math.min(...scoreValues);
  const maxScore = Math.max(...scoreValues);
  const bucketSize = (maxScore - minScore) / numBuckets;

  const buckets = Array.from({ length: numBuckets }, (_, i) => ({
    min: minScore + i * bucketSize,
    max: minScore + (i + 1) * bucketSize,
    count: 0,
  }));

  const getBucketIndexForScore = (score: number | null) =>
    score === null
      ? 0
      : Math.min(Math.floor((score - minScore) / bucketSize), numBuckets - 1);
  scoreValues.forEach((score) => {
    buckets[getBucketIndexForScore(score)].count += 1;
  });

  const maxCount = Math.max(...buckets.map((bucket) => bucket.count));
  const chartWidth = 300;
  const barWidth = chartWidth / numBuckets;
  const chartHeight = 200;

  const currentScore = scores?.[code || ""] || null;

  return (
    <svg width={chartWidth} height={chartHeight}>
      {buckets.map((bucket, index) => {
        const barHeight = (bucket.count / maxCount) * chartHeight;
        return (
          <rect
            key={index}
            x={index * barWidth}
            y={chartHeight - barHeight}
            width={barWidth}
            height={barHeight}
            fill={
              currentScore !== null &&
              getBucketIndexForScore(currentScore) === index
                ? scoreColor(index / numBuckets)
                : "#9ea1a3"
            }
          />
        );
      })}
    </svg>
  );
}
