import { StateCreator } from "zustand";
import { Metric } from "../../../types/geoJson";


interface MetricConfig {
    datapoints: Record<string, string>;
    sources: Record<string, string>;
}


export interface MetricConfigSlice {
    metricConfig: MetricConfig | null;
    setMetricConfig: (metricConfig: MetricConfig) => void;
    getDatapointSource: (metric: Metric) => string | null;
}

export const createMetricConfigSlice: StateCreator<
MetricConfigSlice,
[],
[],
MetricConfigSlice> = (set, get) => ({
    metricConfig: null,
    setMetricConfig: (metricConfig) => set({ metricConfig }),
    getDatapointSource: (metric) => {
        const metricConfig = get().metricConfig;
        const sourceId = metricConfig?.datapoints?.[metric];
        return sourceId ? metricConfig.sources?.[sourceId] : null;
    },
})