import { StateCreator } from "zustand";
import { GeographyLevel, Metric, MetricScores } from "../../../types/geoJson";
import { cloneDeep } from "lodash";

export interface MetricScoreSlice {
    metricScores: MetricScores;
    setMetricScores: (level: GeographyLevel, metric: Metric, scores: Record<string, number>) => void;
}

export const createMetricScoreSlice: StateCreator<
MetricScoreSlice,
[],
[],
MetricScoreSlice> = (set, get) => ({
    metricScores: {},
    setMetricScores: (level, metric, scores) => set((state) => {
        const metricScores = cloneDeep(state.metricScores);
        if (!metricScores[level]) {
            metricScores[level] = {};
        }
        metricScores[level]![metric] = scores;

        return { metricScores };
    })
})