import { create } from "zustand";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { LocationInput } from "../../types/compare";

interface CompareStoreState {
    locations: LocationInput[];
    categoryPath: string;
}
  
interface CompareStoreActions {
    setLocations(locations: LocationInput[]): void;
    addLocation(location: LocationInput): void;
    removeLocation(index: number): void;
    setCategoryPath(path: string): void;
}
  
export const useCompareStore = create<
    CompareStoreState & CompareStoreActions
>((set) => ({
    locations: [],
    categoryPath: "",
    setLocations(locations: LocationInput[]): void {
      set({ locations });
    },
    addLocation(location: LocationInput): void {
      set((state) => {
        if (
          state.locations.find(
            ({ level, id }) => level === location.level && id === location.id
          )
        ) {
          return { locations: state.locations };
        } else {
          return { locations: [...state.locations, location] };
        }
      });
    },
    removeLocation(index: number): void {
      set((state) => ({
        locations: state.locations.filter((_, i) => i !== index),
      }));
    },
    setCategoryPath(path: string): void {
      set({ categoryPath: path });
    },
}));

// Helper to generate the URL
function generateCompareUrl(
  categoryPath: string,
  locations: LocationInput[]
): string {
  const baseUrl = `/compare${categoryPath ? `/${categoryPath}` : ""}`;
  if (locations.length === 0) return baseUrl;

  const params = new URLSearchParams();
  locations.forEach((loc, index) => {
    params.append(`loc${index}`, `${loc.level}:${loc.id}`);
  });

  return `${baseUrl}?${params.toString()}`;
}

// Hook to sync store with URL
export function useCompareUrlSync() {
  const navigate = useNavigate();
  const { locations, categoryPath } = useCompareStore();

  useEffect(() => {
    const url = generateCompareUrl(categoryPath, locations);
    navigate(url, { replace: true });
  }, [locations, categoryPath, navigate]);
}